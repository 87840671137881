<template>
	<div class="app-layer is-active">
		<VOverlay
			:hasBackground="false"
			:hasJsonButton="false"
			:hasCloseButton="false"
			:closeOnBackgroundClick="false"
			:isLarge="true"
			:isVCentered="false"
			:hasFullwidthBody="true"
			:hasScrollableContent="true"
			v-model="overlayIsActive"
			:title="$t('user.userManagement')"
			:useFocusTrap="false"
			:closeWithEscape="false"
		>
			<VTabs>
				<VTabItem :title="$t('user.users')">
					<Suspense>
						<UserList />
					</Suspense>
				</VTabItem>
				<VTabItem :title="$t('user.roles')">
					<Suspense>
						<RoleList />
					</Suspense>
				</VTabItem>
			</VTabs>
		</VOverlay>
	</div>
</template>

<script>
import { getStoreAction } from '@assets/scripts/store/config';

import RoleList from '@modules/UserManagement/materials/structures/RoleList.vue';
import UserList from '@modules/UserManagement/materials/structures/UserList.vue';

export default {
	name: 'TheUserManagementPage',
	components: {
		RoleList,
		UserList,
	},
	data: function () {
		return {
			overlayIsActive: true,
		};
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'USER'));
	},
};
</script>
