<template>
	<div
		class="content-header has-margin has-padding-top-3 has-bottom-divider"
	>
		<div class="field is-grouped is-flex-grow-1 is-align-items-end">
			<VLink
				v-if="canEditUser"
				:text="$t('user.userList.addUser')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="createNewClicked"
				class="has-margin-right-3 has-margin-bottom-05"
			/>

			<VSearch v-model="search" />
		</div>
	</div>

	<div class="scrollable-content">
		<SortableDataTable
			:rowClickedFn="rowClicked"
			:scrollable="false"
			:data="filteredData"
			defaultSort="email"
			:columns="columns"
			:emptyText="$t('user.userList.table.noResults')"
		/>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from '@vue/reactivity';
import {
	getStoreAction,
	getStoreMutation,
} from '@assets/scripts/store/config';
import { formatForTable } from '@modules/UserManagement/components/user';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';
import { GET_USERS } from '@modules/UserManagement/endpoints';

export default {
	name: 'UserList',
	data: function () {
		return {
			search: '',
		};
	},
	async setup() {
		const store = useStore();
		const { t } = useI18n();

		const state = reactive({
			users: [],
		});

		const loadUsers = async () => {
			// get all Users
			state.users = await useApiAsync(GET_USERS);
		};

		// subscribe to store action
		const unsubscribeAction = store.subscribeAction({
			after: async ({ type }) => {
				// check action type
				if (type === getStoreAction('UPDATE_USER_LIST', 'USER')) {
					// reload user list if needed
					await loadUsers();
				}
			},
		});

		await loadUsers();

		return { ...toRefs(state), unsubscribeAction };
	},
	computed: {
		/**
		 * Boolean to indicate whether the current
		 * user can manage users
		 */
		canEditUser: function () {
			return usePermission('Upsert', 'UserManagement');
		},
		columns: function () {
			return [
				{
					label: this.$t('user.userList.table.columns.firstName'),
					field: 'firstName',
					sortable: true,
					searchable: true,
				},
				{
					label: this.$t('user.userList.table.columns.lastName'),
					field: 'lastName',
					sortable: true,
					searchable: true,
				},
				{
					label: this.$t('user.userList.table.columns.email'),
					field: 'email',
					sortable: true,
					searchable: true,
				},
				{
					label: this.$t('user.userList.table.columns.isActive'),
					field: 'isActive',
					sortable: true,
					searchable: true,
				},
				{
					label: this.$t('user.userList.table.columns.lastLogin'),
					field: 'lastLogin',
					sortable: true,
					searchable: true,
					isDate: true,
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.rowClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			];
		},
		tableFields: function () {
			return formatForTable(this.users);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// filter on search string
			return Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		rowClicked({ user }) {
			// open drawer with user information
			this.$store.dispatch(
				getStoreAction('LOAD_AND_SHOW_USER', 'USER'),
				user.guid
			);
		},
		deleteClicked({ id }) {
			// ask confirmation to delete document
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('user.userList.delete.confirm.title'),
				body: this.$t('user.userList.delete.confirm.body'),
				confirmButtonText: this.$t(
					'user.userList.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to delete clicked user
					this.$store.dispatch(
						getStoreAction('DELETE_USER', 'USER'),
						id
					);
				},
			});
		},
		createNewClicked: function () {
			// open drawer with user information
			this.$store.dispatch(getStoreAction('LOAD_AND_SHOW_USER', 'USER'));
		},
	},
	unmounted: function () {
		// unsubscribe from actions
		this.unsubscribeAction();
	},
};
</script>
