<template>
	<div class="content-header has-bottom-divider has-padding-top-3 has-margin">
		<div class="field is-grouped is-flex-grow-1 is-align-items-end">
			<VLink
				v-if="canEditRole"
				:text="$t('user.roleList.addRole')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="createNewClicked"
				class="has-margin-right-3 has-margin-bottom-05"
			/>

			<VSearch v-model="search" />
		</div>
	</div>

	<div class="scrollable-content">
		<SortableDataTable
			:rowClickedFn="rowClicked"
			:scrollable="false"
			:data="filteredData"
			defaultSort="name"
			:columns="columns"
			:emptyText="$t('user.roleList.table.noResults')"
		/>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from '@vue/reactivity';
import { getStoreAction, getStoreMutation } from '@assets/scripts/store/config';
import { formatForTable } from '@modules/UserManagement/components/role';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';
import { GET_ROLES } from '@modules/UserManagement/endpoints';

export default {
	name: 'RoleList',
	data: function () {
		return {
			search: '',
		};
	},
	async setup () {
		const store = useStore();
		const { t } = useI18n();

		const state = reactive({
			roles: [],
		});

		const loadRoles = async () => {
			// get all Roles
			state.roles = await useApiAsync(GET_ROLES);
		};

		// subscribe to store action
		const unsubscribeAction = store.subscribeAction({
			after: async ({ type }) => {
				// check action type
				if (type === getStoreAction('UPDATE_ROLE_LIST', 'USER')) {
					// reload role list if needed
					await loadRoles();
				}
			}
		});

		await loadRoles();

		return { ...toRefs(state), unsubscribeAction };
	},
	computed: {
		/**
		 * Boolean to indicate whether the current
		 * user can manage users
		 */
		canEditRole: function () {
			return usePermission('Upsert', 'UserManagement');
		},
		columns: function() {
			return [
				{
					label: this.$t('user.roleList.table.columns.name'),
					field: 'name',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.rowClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			];
		},
		tableFields: function () {
			return formatForTable(this.roles);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// filter on search string
			return Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		rowClicked({ role }) {
			// open drawer with role information
			this.$store.dispatch(
				getStoreAction('LOAD_AND_SHOW_ROLE', 'USER'),
				role.guid
			);
		},
		deleteClicked({ id }) {
			// ask confirmation to delete document
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('user.roleList.delete.confirm.title'),
				body: this.$t('user.roleList.delete.confirm.body'),
				confirmButtonText: this.$t(
					'user.roleList.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to delete clicked role
					this.$store.dispatch(
						getStoreAction('DELETE_ROLE', 'USER'),
						id
					);
				},
			});
		},
		createNewClicked: function () {
			// open drawer with role information
			this.$store.dispatch(
				getStoreAction('LOAD_AND_SHOW_ROLE', 'USER')
			);
		},
	},
	unmounted: function () {
		// unsubscribe from actions
		this.unsubscribeAction();
	},
};
</script>
